import { faCheck, faTimes } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import IBusinessHour, { dayMap } from '../interfaces/IBusinessHour'

interface Props {
	businessHour: IBusinessHour
	isEditing: boolean
	onChanged: (businessHour: IBusinessHour) => void
}

export const BusinessHour = (props: Props) => {
	const { businessHour, isEditing, onChanged } = props

	return (
		<tr>
			<td className="day">{dayMap[businessHour.name]}</td>
			<td className="indicator">
				{businessHour.open && businessHour.close ? (
					<FontAwesomeIcon icon={faCheck} />
				) : (
					<FontAwesomeIcon icon={faTimes} />
				)}
			</td>
			<td className="open">
				{!isEditing ? (
					businessHour.open
				) : (
					<input
						type="text"
						value={businessHour.open}
						onChange={(e) => onChanged({ ...businessHour, open: e.target.value })}
					/>
				)}
			</td>
			<td className="close">
				{!isEditing ? (
					businessHour.close
				) : (
					<input
						type="text"
						value={businessHour.close}
						onChange={(e) => onChanged({ ...businessHour, close: e.target.value })}
					/>
				)}
			</td>
		</tr>
	)
}
