export default interface IBusinessHour {
    name: keyof typeof dayMap
    open: string
    close: string
}

export const dayMap = {
	Mon: 'Pondělí',
	Tue: 'Úterý',
	Wed: 'Středa',
	Thu: 'Čtvrtek',
	Fri: 'Pátek',
	Sat: 'Sobota',
	Sun: 'Neděle',
}