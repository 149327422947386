import { useEffect, useState } from 'react'
import { useRequireAuth } from '../auth/useRequireAuth'
import Order from '../../interfaces/IOrder'
import { OrdersTable } from '../OrdersTable'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons'
import { Loader } from '../Loader'
import { useApi } from '../auth/useApi'

const PAGE_SIZE = 10

export const Orders = () => {
	document.title = 'Objednávky'
	const [isLoading, setIsLoading] = useState(true)
	const [orders, setOrders] = useState<Order[]>([])
	const [totalOrders, setTotalOrders] = useState(0)
	const [pageCounter, setPageCounter] = useState<number>(1)
	const { api } = useApi()
	useRequireAuth()

	const getOrders = () => {
		setIsLoading(true)
		return api.get(`/order/all?count=${PAGE_SIZE}&page=${pageCounter}`).then((response) => {
			let _orders: Order[] = Object.values(response.data.data.orders)
			setOrders(_orders)
			setTotalOrders(response.data.data.total)
		})
	}

	useEffect(() => {
		Promise.all([getOrders()]).then(() => setIsLoading(false))
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [pageCounter])

	return (
		<div className="page page-orders">
			<div className="page-orders-buttons">
				{totalOrders > PAGE_SIZE && (
					<button
						className="button page-orders-buttons-button page-orders-buttons-button-previous"
						onClick={() => {
							let temp = pageCounter
							++temp
							setPageCounter(temp)
						}}
					>
						<FontAwesomeIcon icon={faChevronLeft} />
						starší
					</button>
				)}
				{pageCounter > 1 && (
					<button
						className="button page-orders-buttons-button page-orders-buttons-button-next"
						onClick={() => {
							let temp = pageCounter
							--temp
							setPageCounter(temp)
						}}
					>
						novější
						<FontAwesomeIcon icon={faChevronRight} />
					</button>
				)}
			</div>
			{isLoading ? <Loader /> : <OrdersTable orders={orders} />}
		</div>
	)
}
