import { useEffect, useState } from 'react'
import { Kpis } from '../Kpis'
import { OrdersTable } from '../OrdersTable'
import { OrderCharts } from '../OrderCharts'
import { useRequireAuth } from '../auth/useRequireAuth'
import IOrder, { ApiOrders } from '../../interfaces/IOrder'
import IChartData from '../../interfaces/IChartData'
import { Loader } from '../Loader'
import { useApi } from '../auth/useApi'

export const Home = () => {
	const [isLoading, setIsLoading] = useState(true)
	const [orders, setOrders] = useState<IOrder[]>([])
	const [chartData, setChartData] = useState<IChartData[]>([])
	const [kpiData, setKpiData] = useState<IChartData[]>([])
	const [numberOfOrders, setNumberOfOrders] = useState<number>(0)
	const { api } = useApi()
	useRequireAuth()

	const getOrders = () => {
		return api.get<ApiOrders>('/order/all?count=5').then(({ data }) => {
			let _orders = Object.values(data.data.orders).sort((a, b) => (a.date < b.date ? 1 : -1))
			setOrders(_orders)
			setNumberOfOrders(data.data.total)
		})
	}

	const getStats = () => {
		return api.get('/order/stats').then((response) => {
			let _chartData: IChartData[] = Array.from(response.data.data)
			setChartData(_chartData)
			setKpiData(_chartData.slice(Math.max(_chartData.length - 2, 0)))
		})
	}

	useEffect(() => {
		setIsLoading(true)
		Promise.all([getOrders(), getStats()]).then(() => setIsLoading(false))
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	return (
		<div className="page page-home">
			{isLoading ? (
				<Loader />
			) : (
				<>
					<OrdersTable orders={orders} />
					<Kpis kpiData={kpiData} numberOfOrders={numberOfOrders} />
					<OrderCharts chartData={chartData} />
				</>
			)}
		</div>
	)
}
