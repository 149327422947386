import { useToken } from './useToken'
import axios, { AxiosInstance } from 'axios'

export const useApi = () => {
	const { token } = useToken()

	const headers = { 'Content-Type': 'application/json' } as any
	if (token) headers['Authorization'] = `Bearer ${token}`

	const baseURL = process.env.NODE_ENV === 'development' ? 'http://ord-api.lo/data' : 'https://api.mealgo.cz/data'

	const api: AxiosInstance = axios.create({
		baseURL,
		headers,
	})

	return {
		api,
	}
}
