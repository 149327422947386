import { FC, useEffect, useState } from 'react'
import c_ from 'classnames'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck, faTimes } from '@fortawesome/free-solid-svg-icons'

interface Props {
	value: string|number
	onChange: (value: any) => void
	selectFrom?: { key: any; value: string }[]
	onSave?: () => Promise<any>
	isToggle?: boolean
}

export const EditableField: FC<Props> = (props) => {
	const { value, onChange, selectFrom, onSave, isToggle, children } = props
	const [active, setActive] = useState(false)
	const [loading, setLoading] = useState(false)
	const [fireToggleSubmit, setFireToggleSubmit] = useState(false)

	const displayValue = () => {
		if (selectFrom) {
			return selectFrom.find((o) => o.value === value)?.key || '(none)'
		}
		return value
	}

	const submit = async () => {
		if (onSave) {
			setLoading(true)
			onSave()
				.then(() => setActive(false))
				.catch(() => {})
				.finally(() => setLoading(false))
		} else {
			setActive(false)
		}
	}

	useEffect(() => {
		fireToggleSubmit && submit() // because state change is async and done after sumbit is called
	}, [value])

	return (
		<div className="o-editable-field">
			{isToggle ? (
				<button
					onClick={() => {
						onChange(!+value)
						setFireToggleSubmit(true)
					}}
					className={c_({ active: +value })}
				>
					{children}
				</button>
			) : !active ? (
				<div onClick={() => setActive(true)} className="o-editable-field__inactive is-clickable">
					<span>{displayValue()}</span>
				</div>
			) : (
				<>
					<div className="o-editable-field__active">
						{!selectFrom ? (
							<input
								className="input"
								type="text"
								value={value}
								onChange={(e) => onChange(e.target.value)}
								onKeyPress={(e) => e.key === 'Enter' && submit()}
								style={{ width: `${('' + value).length + 1}ch` }}
							/>
						) : (
							<select ref="model" onChange={submit}>
								<option value="">(none)</option>
								{selectFrom.map((o) => (
									<option value={o.value} key={o.value} selected={o.value === value}>
										{o.key}
									</option>
								))}
							</select>
						)}
						<div className="o-editable-field__btn-group">
							<button
								onClick={submit}
								disabled={loading}
								title="Confirm Edit"
								className="o-btn o-btn--outline-primary"
							>
								<FontAwesomeIcon icon={faCheck} />
							</button>
							<button
								onClick={() => setActive(false)}
								title="Cancel Edit"
								className="o-btn2 o-btn--outline-primary"
							>
								<FontAwesomeIcon icon={faTimes} />
							</button>
						</div>
					</div>
				</>
			)}
		</div>
	)
}
