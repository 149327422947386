import {
	faCalendarDay,
	faDoorOpen,
	faHourglassEnd,
	faHourglassStart,
	faPencilAlt,
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useEffect, useState } from 'react'
import IBusinessHour from '../../interfaces/IBusinessHour'
import Category from '../../interfaces/ICategory'
import { useRequireAuth } from '../auth/useRequireAuth'
import { BusinessHour } from '../BusinessHour'
import { Loader } from '../Loader'
import { TrueFalseIcon } from '../TrueFalseIcon'
import IRestaurant from '../../interfaces/IRestaurant'
import { useApi } from '../auth/useApi'
import { EditableField } from '../EditableField'

export const Restaurant = (props: any) => {
	const [isLoading, setIsLoading] = useState(true)
	const [editingHours, setEditingHours] = useState(false)
	const [restaurantData, setRestaurantData] = useState<IRestaurant>()
	const [mixedCategory, setMixedCategory] = useState<Category>()
	const [businessHours, setBusinessHours] = useState<IBusinessHour[]>([])

	useRequireAuth()
	const { api } = useApi()

	const getRestaurant = () => {
		api.get<{ data: IRestaurant }>('/property').then((response) => {
			setRestaurantData(response.data.data)
			let _hours = Object.values(response.data.data.hours)
			let _days = Object.keys(response.data.data.hours)
			let _businessHours: IBusinessHour[] = []
			_days.forEach((value: any, i: number) => {
				if (_hours[i][0]) {
					_businessHours.push({
						name: value,
						open: Object.keys(_hours[i][0])[0],
						close: Object.values(_hours[i][0])[0],
					})
				} else {
					_businessHours.push({
						name: value,
						open: '',
						close: '',
					})
				}
			})
			setBusinessHours(_businessHours)
		})
		api.get('/category/all').then((response) => {
			let _categories: Category[] = Object.values(response.data.data)

			let _mixedCategory = _categories.filter((c: Category) => {
				if (c.id === restaurantData?.mix_item_cat) return c
				return null
			})[0]

			setMixedCategory(_mixedCategory)
			setIsLoading(false)
		})
	}

	const pushHours = () => {
		const fields = businessHours.reduce((r, item) => {
			if (item.open && item.close) {
				r[`${item.name}_from`] = item.open
				r[`${item.name}_to`] = item.close
			}
			return r
		}, {} as { [k: string]: string })

		api.post('/property/hours', fields)
	}

	const setData = (fields: any) => {
		setRestaurantData((s) => (s ? { ...s, ...fields } : undefined))
	}

	const pushData = (fields: any) => {
		return api.post('/property', fields)
	}

	useEffect(() => {
		document.title = 'Provozovna'
		getRestaurant()
	}, [])

	return (
		<div className="page page-restaurant">
			{isLoading ? (
				<Loader />
			) : restaurantData ? (
				<>
					<div className="left">
						<section className="page-restaurant-section-general">
							<header className="page-restaurant-section-header">
								<h2 className="page-restaurant-section-header-heading">Obecná nastavení</h2>
							</header>
							<div className="table-container">
								<table className="table restauranttable">
									<thead className="table-header">
										<tr>
											<td colSpan={2}>&nbsp;</td>
										</tr>
									</thead>
									<tbody className="table-body">
										<tr>
											<td>Název provozovny</td>
											<td>
												<EditableField
													value={restaurantData.name}
													onChange={(name) => setData({ name })}
													onSave={() => pushData({ name: restaurantData.name })}
												/>
											</td>
										</tr>
										<tr>
											<td>Adresa provozovny</td>
											<td>
												<EditableField
													value={restaurantData.address}
													onChange={(address) => setData({ address })}
													onSave={() => pushData({ address: restaurantData.address })}
												/>
											</td>
										</tr>
										<tr>
											<td>Telefon</td>
											<td>
												<EditableField
													value={restaurantData.phone}
													onChange={(phone) => setData({ phone })}
													onSave={() => pushData({ phone: restaurantData.phone })}
												/>
											</td>
										</tr>
										<tr>
											<td>Platební metody</td>
											<td className="payments">
												<section>
													<span>Hotovost</span>
													<EditableField
														value={restaurantData.pymt[0]}
														onChange={(e) =>
															setData({
																pymt: `${+e}${restaurantData.pymt.substring(1)}`,
															})
														}
														onSave={() =>
															pushData({
																pymt: restaurantData.pymt,
															})
														}
														isToggle
													>
														<TrueFalseIcon val={+restaurantData.pymt[0]} />
													</EditableField>
												</section>
												<section>
													<span>Karta</span>
													<EditableField
														value={restaurantData.pymt[1]}
														onChange={(e) =>
															setData({
																pymt: `${
																	restaurantData.pymt[0]
																}${+e}${restaurantData.pymt.substring(2)}`,
															})
														}
														onSave={() =>
															pushData({
																pymt: restaurantData.pymt,
															})
														}
														isToggle
													>
														<TrueFalseIcon val={+restaurantData.pymt[1]} />
													</EditableField>
												</section>
												<section>
													<span>Stravenky</span>
													<EditableField
														value={restaurantData.pymt[2]}
														onChange={(e) =>
															setData({
																pymt: `${restaurantData.pymt.substring(0, 2)}${+e}`,
															})
														}
														onSave={() =>
															pushData({
																pymt: restaurantData.pymt,
															})
														}
														isToggle
													>
														<TrueFalseIcon val={+restaurantData.pymt[2]} />
													</EditableField>
												</section>
												<section>
													<span>Online</span>
													<TrueFalseIcon val={restaurantData.onlinepay_meta} />
												</section>
											</td>
										</tr>
										<tr>
											<td>Zobrazovat kód jídla</td>
											<td>
												<EditableField
													value={restaurantData.show_item_code}
													onChange={(show_item_code) =>
														setData({
															show_item_code,
														})
													}
													onSave={() =>
														pushData({
															show_item_code: restaurantData.show_item_code,
														})
													}
													isToggle
												>
													<TrueFalseIcon val={restaurantData.show_item_code} />
												</EditableField>
											</td>
										</tr>
										<tr>
											<td>Zobrazovat složení jídel</td>
											<td>
												<EditableField
													value={restaurantData.show_ingreds}
													onChange={(show_ingreds) =>
														setData({
															show_ingreds,
														})
													}
													onSave={() =>
														pushData({
															show_ingreds: restaurantData.show_ingreds,
														})
													}
													isToggle
												>
													<TrueFalseIcon val={restaurantData.show_ingreds} />
												</EditableField>
											</td>
										</tr>
										<tr>
											<td>Upozornění na nové objednávky emailem</td>
											<td>
												<EditableField
													value={restaurantData.send_emails}
													onChange={(send_emails) =>
														setData({
															send_emails,
														})
													}
													onSave={() =>
														pushData({
															send_emails: restaurantData.send_emails,
														})
													}
													isToggle
												>
													<TrueFalseIcon val={restaurantData.send_emails} />
												</EditableField>
											</td>
										</tr>
										<tr>
											<td>Půlená pizza</td>
											<td>
												Zapnuto pro <b>{mixedCategory?.name}</b>
											</td>
										</tr>
										<tr>
											<td>Poznámka - hlavička stránky</td>
											<td>{restaurantData.options.memo_head}</td>
										</tr>
										<tr>
											<td>Poznámka - potvrzení objednávky</td>
											<td>{restaurantData.options.memo_2_left}</td>
										</tr>
										<tr>
											<td>Facebook</td>
											<td>{restaurantData.options.social_links.fb}</td>
										</tr>
										<tr>
											<td>Instagram</td>
											<td>{restaurantData.options.social_links.ig}</td>
										</tr>
									</tbody>
								</table>
							</div>
						</section>
					</div>
					<div className="right">
						<section className="page-restaurant-section-hours">
							<header className="page-restaurant-section-header">
								<h2 className="page-restaurant-section-header-heading">Příjem objednávek</h2>
							</header>
							<div className="table-container">
								<table className="table">
									<thead className="table-header">
										<tr>
											<th>
												<FontAwesomeIcon icon={faCalendarDay} />
												Den
											</th>
											<th>
												<FontAwesomeIcon icon={faDoorOpen} />
												Otevřeno
											</th>
											<th>
												<FontAwesomeIcon icon={faHourglassStart} />
												Začátek
											</th>
											<th>
												<FontAwesomeIcon icon={faHourglassEnd} />
												Konec
											</th>
											<th>
												<button
													className="button productstable-body-button productstable-body-button--edit-product"
													onClick={() => {
														setEditingHours((s) => !s)
													}}
												>
													<FontAwesomeIcon icon={faPencilAlt} />
												</button>
											</th>
										</tr>
									</thead>
									<tbody className="table-body">
										{businessHours.map((b) => {
											return (
												<BusinessHour
													key={b.name}
													businessHour={b}
													isEditing={editingHours}
													onChanged={(changed) =>
														setBusinessHours((state) =>
															state.map((h) => (h.name === changed.name ? changed : h)),
														)
													}
												/>
											)
										})}
									</tbody>
								</table>
								{editingHours && (
									<button
										className="button productstable-body-button productstable-body-button--save-product"
										onClick={pushHours}
									>
										Uložit
									</button>
								)}
							</div>
						</section>
						<section className="page-restaurant-section-account">
							<header className="page-restaurant-section-header">
								<h2 className="page-restaurant-section-header-heading">Nastavení účtu</h2>
							</header>
							<div className="table-container">
								<table className="table">
									<thead className="table-header">
										<tr>
											<td colSpan={2}>&nbsp;</td>
										</tr>
									</thead>
									<tbody className="table-body">
										<tr>
											<td>Jméno</td>
											<td>demo</td>
										</tr>
										<tr>
											<td>Nové heslo</td>
											<td>
												<input type="password" />
											</td>
										</tr>
										<tr>
											<td>Nové heslo znovu</td>
											<td>
												<input type="password" />
											</td>
										</tr>
										<tr>
											<td>E-mail</td>
											<td>{restaurantData?.email}</td>
										</tr>
									</tbody>
								</table>
							</div>
						</section>
					</div>
				</>
			) : null}
		</div>
	)
}
